<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-profile
            :save_loading="loading"
            :module_name="page.name"
            :record="record"
            @change="save"
            @clean_cache="cleanCache"
            :user_type="record.type"
            :profile="true">
          <template>
            <v-text-field :label="$store.getters.translate('email')" v-model="record.email"/>
          </template>
        </base-profile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../components/commonComponents/BaseBreadcrumb";
import BaseProfile from "../components/commonComponents/BaseProfile";

export default {
  components: {
    BaseBreadcrumb,
    BaseProfile,
  },
  name: "Profile",
  data() {
    return {
      page: {
        title: this.$store.getters.translate("profile"),
        name: "profile",
      },
      errors: [],
      valid: false,
      loading: false,
      email: "",
      password: "",
      record: {
        language: "nl",
        notify_is_on: true,
        person: {
          first_name: "",
          insertion: "",
          last_name: "",
          initials: "",
          phonenumber: "",
          mobilenumber: "",
          emailaddresses: [],
          faxnumber: "",
          place_of_birth: "",
          country_of_birth_id: 1,
          nationality_id: 1,
          date_of_birth: "",
          gender: "",
          financial_name: "",
          candidate: {
            id: 0,
            is_dyslexic: false,
            is_dyscalc: false,
            reference: "",
            preferred_certificate_language: "",
            certificates: [],
            notes: [],
          },
        },
        settings: { candidate_portal_welcome: null },
        type: 'employee',
        notifications: [],
        notes: [],
      },
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      let person = this.record.person;
      this.$http
        .get(this.$store.getters.appUrl + "v2/profile")
        .then((response) => {
          this.record = response.data;
          if (!this.record.person) {
            this.record.person = person;
          }
          this.loading = false;
          this.$store.dispatch("setProfileName", this.record.person ? this.record.person.name : this.record.email);
          this.$store.dispatch("setProfileImage", this.record.person && this.record.person.file ? this.record.person.file.full_path : null);
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    cleanCache() {
      this.$toasted.success("cleaned_cache");
      localStorage.clear();
      window.location.reload();
    },
    save() {
      if(!this.loading) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/" + this.page.name, this.record)
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                this.$toasted.success(this.$store.getters.translate("success"));
                this.$store.dispatch("setLanguage", this.record.language);
                this.$store.dispatch("setNotifyIsOn", this.record.notify_is_on);
                this.load();
              } else {
                var errorMessage = response.data.message;
                var errors = Object.keys(response.data.message);
                this.$toasted.error([errorMessage[errors[0]]]);
              }
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      }
    },
  },
};
</script>
